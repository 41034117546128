/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
    padding: 0;
    margin: 0;
}

.paddingTiny {
    padding: 0.5rem;
}